
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;700;800&family=Lato:ital,wght@0,400;0,700;1,400&display=swap');
/*
font-family: 'Epilogue', sans-serif;
font-family: 'Lato', sans-serif;
*/

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF7F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%;
}

body, html {
  min-width: 900px;
}

@media screen and (min-width: 1361px) and (max-width: 1370px) {
  html {font-size: 62.5%;}
}

@media screen and (min-width: 1351px) and (max-width: 1360px) {
  html {font-size: 62.042%;}
}

@media screen and (min-width: 1341px) and (max-width: 1350px) {
  html {font-size: 61.585%;}
}

@media screen and (min-width: 1331px) and (max-width: 1340px) {
  html {font-size: 61.127%;}
}

@media screen and (min-width: 1321px) and (max-width: 1330px) {
  html {font-size: 60.67%;}
}

@media screen and (min-width: 1311px) and (max-width: 1320px) {
  html {font-size: 60.212%;}
}

@media screen and (min-width: 1301px) and (max-width: 1310px) {
  html {font-size: 59.755%;}
}

@media screen and (min-width: 1291px) and (max-width: 1300px) {
  html {font-size: 59.297%;}
}

@media screen and (min-width: 1281px) and (max-width: 1290px) {
  html {font-size: 58.84%;}
}

@media screen and (min-width: 1271px) and (max-width: 1280px) {
  html {font-size: 58.382%;}
}

@media screen and (min-width: 1261px) and (max-width: 1270px) {
  html {font-size: 57.925%;}
}

@media screen and (min-width: 1251px) and (max-width: 1260px) {
  html {font-size: 57.467%;}
}

@media screen and (min-width: 1241px) and (max-width: 1250px) {
  html {font-size: 57.01%;}
}

@media screen and (min-width: 1231px) and (max-width: 1240px) {
  html {font-size: 56.552%;}
}

@media screen and (min-width: 1221px) and (max-width: 1230px) {
  html {font-size: 56.094%;}
}

@media screen and (min-width: 1211px) and (max-width: 1220px) {
  html {font-size: 55.637%;}
}

@media screen and (min-width: 1201px) and (max-width: 1210px) {
  html {font-size: 55.179%;}
}

@media screen and (min-width: 1191px) and (max-width: 1200px) {
  html {font-size: 54.722%;}
}

@media screen and (min-width: 1181px) and (max-width: 1190px) {
  html {font-size: 54.264%;}
}

@media screen and (min-width: 1171px) and (max-width: 1180px) {
  html {font-size: 53.807%;}
}

@media screen and (min-width: 1161px) and (max-width: 1170px) {
  html {font-size: 53.349%;}
}

@media screen and (min-width: 1151px) and (max-width: 1160px) {
  html {font-size: 52.892%;}
}

@media screen and (min-width: 1141px) and (max-width: 1150px) {
  html {font-size: 52.434%;}
}

@media screen and (min-width: 1131px) and (max-width: 1140px) {
  html {font-size: 51.977%;}
}

@media screen and (min-width: 1121px) and (max-width: 1130px) {
  html {font-size: 51.519%;}
}

@media screen and (min-width: 1111px) and (max-width: 1120px) {
  html {font-size: 51.061%;}
}

@media screen and (min-width: 1101px) and (max-width: 1110px) {
  html {font-size: 50.604%;}
}

@media screen and (min-width: 1091px) and (max-width: 1100px) {
  html {font-size: 50.146%;}
}

@media screen and (min-width: 1081px) and (max-width: 1090px) {
  html {font-size: 49.689%;}
}

@media screen and (min-width: 1071px) and (max-width: 1080px) {
  html {font-size: 49.231%;}
}

@media screen and (min-width: 1061px) and (max-width: 1070px) {
  html {font-size: 48.774%;}
}

@media screen and (min-width: 1051px) and (max-width: 1060px) {
  html {font-size: 48.316%;}
}

@media screen and (min-width: 1041px) and (max-width: 1050px) {
  html {font-size: 47.859%;}
}

@media screen and (min-width: 1031px) and (max-width: 1040px) {
  html {font-size: 47.401%;}
}

@media screen and (min-width: 1021px) and (max-width: 1030px) {
  html {font-size: 46.944%;}
}

@media screen and (min-width: 1011px) and (max-width: 1020px) {
  html {font-size: 46.486%;}
}

@media screen and (min-width: 1001px) and (max-width: 1010px) {
  html {font-size: 46.029%;}
}

@media screen and (min-width: 991px) and (max-width: 1000px) {
  html {font-size: 45.571%;}
}

@media screen and (min-width: 981px) and (max-width: 990px) {
  html {font-size: 45.113%;}
}

@media screen and (min-width: 971px) and (max-width: 980px) {
  html {font-size: 44.656%;}
}

@media screen and (min-width: 961px) and (max-width: 970px) {
  html {font-size: 44.198%;}
}

@media screen and (min-width: 951px) and (max-width: 960px) {
  html {font-size: 43.741%;}
}

@media screen and (min-width: 941px) and (max-width: 950px) {
  html {font-size: 43.283%;}
}

@media screen and (min-width: 931px) and (max-width: 940px) {
  html {font-size: 42.826%;}
}

@media screen and (min-width: 921px) and (max-width: 930px) {
  html {font-size: 42.368%;}
}

@media screen and (min-width: 911px) and (max-width: 920px) {
  html {font-size: 41.911%;}
}

@media screen and (min-width: 901px) and (max-width: 910px) {
  html {font-size: 41.453%;}
}

@media screen and (min-width: 900px) and (max-width: 900px) {
  html {font-size: 41.453%;}
}

@media screen and (min-width: 1371px) and (max-width: 1380px) {
  html {font-size: 62.958%;}
}

@media screen and (min-width: 1381px) and (max-width: 1390px) {
  html {font-size: 63.415%;}
}

@media screen and (min-width: 1391px) and (max-width: 1400px) {
  html {font-size: 63.873%;}
}

@media screen and (min-width: 1401px) and (max-width: 1410px) {
  html {font-size: 64.33%;}
}

@media screen and (min-width: 1411px) and (max-width: 1420px) {
  html {font-size: 64.788%;}
}

@media screen and (min-width: 1421px) and (max-width: 1430px) {
  html {font-size: 65.245%;}
}

@media screen and (min-width: 1431px) and (max-width: 1440px) {
  html {font-size: 65.703%;}
}

@media screen and (min-width: 1441px) and (max-width: 1450px) {
  html {font-size: 66.16%;}
}

@media screen and (min-width: 1451px) and (max-width: 1460px) {
  html {font-size: 66.618%;}
}

@media screen and (min-width: 1461px) and (max-width: 1470px) {
  html {font-size: 67.075%;}
}

@media screen and (min-width: 1471px) and (max-width: 1480px) {
  html {font-size: 67.533%;}
}

@media screen and (min-width: 1481px) and (max-width: 1490px) {
  html {font-size: 67.99%;}
}

@media screen and (min-width: 1491px) and (max-width: 1500px) {
  html {font-size: 68.448%;}
}

@media screen and (min-width: 1501px) and (max-width: 1510px) {
  html {font-size: 68.906%;}
}

@media screen and (min-width: 1511px) and (max-width: 1520px) {
  html {font-size: 69.363%;}
}

@media screen and (min-width: 1521px) and (max-width: 1530px) {
  html {font-size: 69.821%;}
}

@media screen and (min-width: 1531px) and (max-width: 1540px) {
  html {font-size: 70.278%;}
}

@media screen and (min-width: 1541px) and (max-width: 1550px) {
  html {font-size: 70.736%;}
}

@media screen and (min-width: 1551px) and (max-width: 1560px) {
  html {font-size: 71.193%;}
}

@media screen and (min-width: 1561px) and (max-width: 1570px) {
  html {font-size: 71.651%;}
}

@media screen and (min-width: 1571px) and (max-width: 1580px) {
  html {font-size: 72.108%;}
}

@media screen and (min-width: 1581px) and (max-width: 1590px) {
  html {font-size: 72.566%;}
}

@media screen and (min-width: 1591px) and (max-width: 1599px) {
  html {font-size: 72.566%;}
}

@media screen and (max-width: 899px) {
  html {font-size: 41.453%;}
}

@media screen and (min-width: 1600px) {
  html {font-size: 72.566%;}
}
